<template>
  <div class="header">
    <!-- tablet and mobile -->
    <el-header class="hidden-lg-and-up">
      <el-menu
        ref="mainMenuMobile"
        class="el-menu-wrapper hidden-lg-and-up"
        :style="commonMarginClass"
        mode="horizontal"
        menu-trigger="click"
        popper-class="custom-wrapper"
        @open="modalOpen"
        @close="modalClose"
      >
        <el-menu-item class="logo-container hidden-lg-and-up">
          <a href="https://www.faria.org/">
            <img
              src="https://www.faria.org/wp-content/uploads/2021/05/faria-text-icon.png"
              alt="FariaOne"
              style="height: 36px;width: 76.9062px;"
            >
          </a>
        </el-menu-item>
        <el-sub-menu
          id="mobile-nav-sub-menu"
          class="mobile-nav-sub-menu"
          index="mobile-nav-sub-menu"
          popper-class="mobile-nav-sub-menu-popper"
          :teleported="false"
        >
          <template #title>
            <img
              :src="getImgUrl('mobile-bar.png')"
              alt="mobile-bar"
            >
          </template>
          <span class="mobile-sub-menus-container">
            <el-menu-item
              v-for="{ title, index: mainIndex, href, isRoot } in mobileMenuLinks"
              :key="mainIndex"
              :index="mainIndex"
              :class="[
                isRoot ? '' : 'not-root-sub-menus-item',
                'mobile-sub-menus-item',
                'product-item'
              ]"
            >
              <template #title>
                <a :href="href">{{ title }}</a>
              </template>
            </el-menu-item>
            <el-menu
              class="el-menu-vertical-more"
            >
              <el-sub-menu
                index="1"
                popper-class="el-sub-menu-more"
                collapse-open-icon="close"
                collapse-close-icon="close"
              >
                <template #title>
                  <span>More</span>
                </template>
                <el-menu-item
                  v-for="({ href, text }, index) in mobileStudyMenuLinks.menuLinks"
                  :key="index"
                  :index="`${index + 1}`"
                >
                  <a :href="href">{{ text }}</a>
                </el-menu-item>
              </el-sub-menu>
            </el-menu>
            <li class="nav-contact-sales hidden-lg-and-up">
              <a href="https://www.faria.org/contactsales.">Contact Sales</a>
            </li>
            <el-menu
              class="el-menu-vertical-login"
            >
              <el-sub-menu
                index="1"
                popper-class="el-sub-menu-login"
                collapse-open-icon="close"
                collapse-close-icon="close"
              >
                <template #title>
                  <span>Login</span>
                </template>
                <el-menu-item
                  v-for="({ href, text }, index) in mobileLoginMenuLinks"
                  :key="index"
                  :index="`${index + 1}`"
                >
                  <a :href="href">{{ text }}</a>
                </el-menu-item>
              </el-sub-menu>
            </el-menu>
          </span>
        </el-sub-menu>
      </el-menu>
    </el-header>

    <!-- destop -->
    <el-header
      ref="header"
      class="hidden-md-and-down"
    >
      <el-menu
        ref="mainMenuDesktop"
        class="el-menu-wrapper"
        :style="{
          marginLeft: headerMargin + 'px',
          marginRight: headerMargin + 'px',
          width: width,
          'max-width': maxWidth
        }"
        mode="horizontal"
      >
        <el-menu-item class="logo-container">
          <a href="https://www.faria.org/">
            <img
              src="https://www.faria.org/wp-content/uploads/2021/03/cropped-feg_icon-01.png"
              alt="FariaOne"
            >
            <span class="hidden-lg-and-up">Faria</span>
          </a>
        </el-menu-item>
        <el-menu-item class="learning hidden-md-and-down">
          <a href="https://www.faria.org/learning">Learning</a>
        </el-menu-item>
        <el-menu-item class="admissions hidden-md-and-down">
          <a href="https://www.faria.org/admissions">Admissions</a>
        </el-menu-item>
        <el-menu-item class="home-school hidden-md-and-down">
          <a href="https://www.faria.org/schoolhome">School-to-Home</a>
        </el-menu-item>
        <el-menu-item class="online-course hidden-md-and-down">
          <a href="https://www.faria.org/online">Online Courses</a>
        </el-menu-item>
        <el-menu-item class="study hidden-md-and-down">
          <a href="https://www.faria.org/study">Study</a>
        </el-menu-item>
        <el-menu-item class="help-logo hidden-md-and-down">
          <el-tooltip
            content="Help"
            placement="bottom"
            popper-class="help-popper"
          >
            <a href="https://help.faria.org/hc/en-us">
              <img
                src="https://www.faria.org/wp-content/uploads/2021/03/icon-help-blue.svg"
                alt="FariaOne"
              >
            </a>
          </el-tooltip>
        </el-menu-item>
        <el-menu-item class="nav-button nav-contact-sales hidden-md-and-down">
          <a
            href="https://www.faria.org/contactsales"
            target="_blank"
          >Contact Sales</a>
        </el-menu-item>
        <el-sub-menu
          ref="loginMenu"
          class="nav-login hidden-md-and-down"
          index="1"
          :teleported="false"
          :show-timeout="0"
        >
          <template #title>
            <span class="sub-menu-title">
              Login
            </span>
          </template>
          <el-menu-item index="1-1">
            <a href="https://www.managebac.com/login">ManageBac</a>
          </el-menu-item>
          <el-menu-item index="1-2">
            <a href="http://openapply.com/login">OpenApply</a>
          </el-menu-item>
          <el-menu-item index="1-3">
            <a href="https://www.schoolsbuddy.com/login">SchoolsBuddy</a>
          </el-menu-item>
          <el-menu-item index="1-4">
            <a href="https://pamojaeducation.com/login">Pamoja</a>
          </el-menu-item>
          <el-menu-item>
            <a
              href="https://oxfordstudycourses.openapply.com/parents/sign_in"
            >OSC</a>
          </el-menu-item>
        </el-sub-menu>
      </el-menu>
    </el-header>
  </div>
</template>

<script>
import "element-plus/theme-chalk/display.css";
import "element-plus/theme-chalk/display.css";
import screenSizeMixin from "../mixins/screenSizeMixin.js";

export default {
  name: "CommunityHeader",
  mixins: [screenSizeMixin],
	data() {
    return {
      mobileMenuLinks: [
        {
          title: "All Services",
          href: "https://www.faria.org/",
          isRoot: true,
        },
        {
          title: "Learning",
          href: "https://managebac.com/",
          isRoot: false,
        },
        {
          title: "Admissions",
          href: "https://www.openapply.com/",
          isRoot: false,
        },
        {
          title: "School-to-Home",
          href: "https://www.schoolsbuddy.com/",
          isRoot: false,
        },
        {
          title: "Online Courses",
          href: "https://pamojaeducation.com/",
          isRoot: false,
        },
        {
          title: "Study",
          href: "https://oxfordstudycourses.com/educator",
          isRoot: false,
        },
      ],
      mobileStudyMenuLinks:
        {
          title: "More",
          index: "study-mobile-sub-menu",
          menuLinks: [
            {
              href: "https://pamojaeducation.com/blog",
              text: "Blog",
            },
            {
              href: "https://pamojaeducation.com/resource-library",
              text: "Resource Library",
            },
            {
              href: "https://events.fariaedu.com/?product=pamoja",
              text: "Events",
            },
            {
              href: "https://help.pamojaeducation.com/hc/en-us",
              text: "Support",
            },
            {
              href: "https://pamojaeducation.com/careers",
              text: "Careers",
            },
            {
              href: "https://pamojaeducation.com/about",
              text: "About",
            },
          ]
        },
      mobileLoginMenuLinks: [
        {
          href: "https://www.managebac.com/login",
          text: "ManageBac"
        },
        {
          href: "http://openapply.com/login",
          text: "OpenApply"
        },
        {
          href: "https://www.schoolsbuddy.com/login",
          text: "SchoolsBuddy"
        },
        {
          href: "https://pamojaeducation.com/login",
          text: "Pamoja"
        },
        {
          href: "https://oxfordstudycourses.openapply.com/parents/sign_in",
          text: "OSC"
        }
      ],
		}
	},
	computed: {
    commonMarginClass() {
      return {
        marginLeft: `${this.headerMargin}px`,
        marginRight: `${this.headerMargin}px`,
        width: this.width,
        maxWidth: this.maxWidth
      };
    }
	},
  mounted() {
    this.handleScrolling();
  },
  unmounted() {
    window.removeEventListener("scroll");
  },
  methods: {
    getImgUrl(imagePath) {
      return require(`../assets/community/${imagePath}`);
    },
    modalOpen(index) {
      if (index === "mobile-nav-sub-menu") {
      const body = document.querySelector("body");
      body.style.overflow = "hidden"
      }

    },
     modalClose(index) {
      if (index === "mobile-nav-sub-menu") {
      const body = document.querySelector("body");
      body.style.overflow = "auto"
      }
    },
    handleScrolling() {
      if (this.windowWidth > 980) {
        window.addEventListener("scroll", () => {
          const scrollPosition = window.pageYOffset;
          const headerEl = this.$refs.header.$el;

          if (scrollPosition >= 45) {
            headerEl.classList.add("header-sticky");
          } else {
            headerEl.classList.remove("header-sticky");
          }
        });
      }
    },
	},
}
</script>

<style lang="scss" scoped>
@import "../css/default-settings";

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
}

::v-deep(.el-header) {
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: fixed !important;
  top: 0;
  z-index: 99;
  width: 100%;
  padding: 0;
  height: 76px !important;
}

.el-menu-wrapper {
  display: flex;
  height: 100%;
  border: 0;
  align-items: center;
  width: auto;
  border-bottom: none !important;

  li:hover {
    background-color: #fff !important;
  }


  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #333333 !important;
    margin-left: 0px !important;
    width: 100%;
  }
}


.learning {
  a {
    padding: 8px !important;
    display: inline-block;
    height: 30px;
    line-height: 12px;
  }
  a:hover {
    color: #fff !important;
    background: #336dcd !important;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    border-radius: 5px;
  }

  &.hidden-md-and-down {
    padding-left: 170px;
    padding-right: 10px;
  }
}

.admissions {
  a {
    padding: 8px !important;
    display: inline-block;
    height: 30px;
    line-height: 12px;
  }

  a:hover {
    color: #fff !important;
    background: #00ce6a;
    border-radius: 5px;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  &.hidden-md-and-down {
    padding-right: 10px;
    padding-left: 0px;
  }
}

.home-school {
  a {
    padding: 8px !important;
    display: inline-block;
    height: 30px;
    line-height: 12px;
  }

  a:hover {
    color: #fff !important;
    background: #9755ce;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    border-radius: 5px;
  }

  &.hidden-md-and-down {
    padding-right: 10px;
    padding-left: 0px;
  }
}

.online-course {
  a {
    padding: 8px !important;
    display: inline-block;
    height: 30px;
    line-height: 12px;
  }

  a:hover {
    color: #fff !important;
    background: #f06053;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    border-radius: 5px;
  }

  &.hidden-md-and-down {
    padding-right: 10px;
    padding-left: 0px;
  }
}

.study {
  a {
    padding: 8px !important;
    display: inline-block;
    height: 30px;
    line-height: 12px;
  }

  a:hover {
    color: #fff !important;
    background: #da5b2b;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    border-radius: 5px;
  }

  &.hidden-md-and-down {
    padding-left: 0px;
    padding-right: 10px;
  }
}

.logo-container {
  padding: 0px;

  img {
    width: 36px;
  }

  span {
    font-size: 24px;
    font-weight: 700;
  }
}

.help-logo {
  margin-right: 2px !important;
  width: 38px;

  img {
    width: 20px;
  }

  &.hidden-md-and-down {
    margin-left: 120px;
    padding-left: 3px;
    padding-right: 12px;
  }
}

.help-label-logo {
  color: #333333;
}

.nav-button {
  a {
    font-size: 14px;
    padding: 8px 15px 10px !important;
    transition: all 0.4s ease-in-out;
    border-radius: 4px;
    color: #fff !important;
    font-weight: 400;
  }

  a:hover {
    color: #fff !important;
    background: #1aaf5d !important;
    opacity: 1 !important;
    transition: all 0.4s ease-in-out;
  }
}

.nav-contact-sales {
  display: flex;
  height: 34px !important;
  margin: 0 0 -2px 0;
  padding: 0;
  width: 117.453px;

  a {
    background: #1ecd6e !important;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    line-height: 14px;
    margin-left: -6px;
  }

  a:hover {
    background: #1aaf5d;
  }

  &.hidden-lg-and-up {
    display: flex;
    width: 102%;
    height: 44px !important;
    margin: 4px -4px 0px 0px !important;
    padding: 0px 0px 0px 4px !important;

    a {
      width: 100%;
      padding-left: 14px;
      padding-top: 16px;
      border-radius: 5px;
      color: #fff !important;
    }

    a:hover {
      opacity: 0.7;
      background: #1ecd6e !important;
    }
  }
}

.nav-login {
  display: flex;
  height: 32px;
  margin-left: 20px;
  margin-bottom: 2px;
  width: 65.4531px;

  ::v-deep(.el-sub-menu) {
    margin-top: 16px;
  }

  ::v-deep(.el-sub-menu__title) {
    background: #478cfe !important;
    color: #fff !important;
    transition: all 0.4s ease-in-out;
    border-radius: 4px;
    padding: 6px 15px 6px;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }

  ::v-deep(.el-sub-menu__icon-arrow) {
    display: none;
  }

  ::v-deep(.el-menu--horizontal) {
    border-right: none;
    text-align: left;
  }

  ::v-deep(.el-menu--horizontal) .el-menu .el-menu-item {
    background: #f5f5f5;
    float: none;
    height: 34px;
    line-height: 36px;
    padding: 0 20px;
    color: #333;
    font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
  }

  ::v-deep(.el-menu--horizontal) .el-menu-item:not(.is-disabled):hover {
    color: #fff !important;
    background: #003864 !important;
    a {
      color: #fff !important;
    }
  }

  &.hidden-md-and-down {
    cursor: text !important;

    ::v-deep(.el-menu--horizontal) {
      border-top: 2px solid #06426e;
      top: 74px !important;
      margin-left: -11.5rem;
      padding: 0 !important;
      width: 250px;
      background: #f5f5f5;
      height: 185px;
      overflow-wrap: break-word;
      overflow: visible;
    }

    ::v-deep(.el-sub-menu__title) {
      pointer-events: none;
      display: flex !important;
      align-items: center !important;
      height: 32px !important;
      width: 65.4531px !important;
      font-weight: 400 !important;
    }

    ::v-deep(.el-menu--popup-bottom-start) {
      background: #f5f5f5;
      margin-top: 0px !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      border-radius: 0 0 3px 3px;
      box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
        0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
      padding: 0 !important;
      border-top: 0 !important;
      height: 100%;
      max-height: 100%;
      width: 100%;
    }

    ::v-deep(.el-popper) {
      top: 74px !important;
    }

    ::v-deep(.el-menu-item) {
      a {
        width: 100%;
        display: inline-block;
        margin-bottom: 6px;
      }
    }

  }

  &.is-opened {
    ::v-deep(.el-sub-menu__title) {
      color: #fff !important;
      background: #336dcd !important;
      opacity: 1 !important;
    }
  }
}

.mobile-nav-sub-menu {
  color: #2ea3f2 !important;
  margin-left: auto;

  ::v-deep(.el-sub-menu__icon-arrow) {
    display: none;
  }

  ::v-deep(.el-menu--horizontal) {
    text-align: left !important;
  }

  ::v-deep(.el-menu--popup) {
    padding: 5%;
    border-top: 3px solid #2ea3f2;
  }

  ::v-deep(.el-menu--horizontal) {
    left: 5px;
  }

  ::v-deep(.el-sub-menu__title) {
    padding: 0;
    color: #2ea3f2 !important;
  }

  a {
    margin-left: 4px;
  }

  ::v-deep(.mobile-nav-sub-menu-popper) {
    width: 100%;
    left: 0 !important;
    top: 75px !important;
    max-height: 700px;
  }
}

.mobile-sub-menus-container {
  li {
    padding: 0px;
  }

  li a img {
    max-height: 30px;
    margin-right: 9px;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  ::v-deep(.el-menu--horizontal) {
    position: initial !important;
  }

  ::v-deep(.el-sub-menu__icon-arrow) {
    display: block;
    right: 10px !important;
  }

  ::v-deep(.el-icon-arrow-right):before {
    content: "\2b";
    font-size: 16px;
  }

  ::v-deep(.el-menu--popup) {
    padding: 0 5%;
    border-top: 0;
    box-shadow: none;
  }


  ::v-deep(.el-menu-vertical-more) {
      border-top: none !important;

      .el-sub-menu {
        .el-sub-menu__title {
          height: 44px;
          color: #000!important;
          font-weight: 700;
          margin-left: 2px;
          margin-top: 4px;
          border-bottom: none;
        }

        .el-menu--inline {
          margin: 0 24px;
          li {
            height: 51px!important;
            border-bottom: 1px solid rgba(0,0,0,.03);
            padding-left: 16px;
          }
        }
      }
  }

  ::v-deep(.el-menu-vertical-login) {
      border-top: none !important;
      margin-left: 2px;

      .el-sub-menu{
        .el-sub-menu__title {
            height: 44px;
            font-weight: 700;
            margin-left: 2px;
            margin-right: -8px;
            margin-top: 4px;
            background: #306cd5 !important;
            color: #fff !important;
            transition: all .4s ease-in-out;
            border-radius: 5px;
            font-weight: 400;
            font-family: Open Sans,Helvetica,Arial,Lucida,sans-serif;
            line-height: 23px!important;
            padding-left: 14px;
        }

        .el-menu--inline {
          margin: 0 24px;
          li {
            height: 51px!important;
            border-bottom: 1px solid rgba(0,0,0,.03);
            padding-left: 16px;
          }
        }
      }
  }
}

.mobile-sub-menus-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  line-height: 23px !important;

  &.product-item {
    height: 44px !important;
    display: flex;
    align-items: center;
  }

  &.not-root-sub-menus-item {
    a {
      margin-left: 12px !important;
    }
  }

  a {
    color: #222233 !important;
  }

  ::v-deep(.el-menu--popup) {
    padding: 0px 0px;
    border-top: 0;
  }

  ::v-deep(.el-sub-menu__title) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  }

  ::v-deep(.el-sub-menu__title):hover {
    opacity: 0.7;
  }

  &.login-mobile-sub-menu {
    margin-left: -2px;
    margin-right: 0px;
    border-bottom: none;
    margin-top: 10px;
    padding: 0px 0px 0px 0px !important;

    .el-sub-menu__title {
      background: #306cd5 !important;
      color: #fff !important;
      transition: all 0.4s ease-in-out;
      border-radius: 5px;
      height: 38px;
      width: 100%;
      padding-left: 16px;
    }

    ::v-deep(.el-sub-menu__title):hover {
      opacity: 1;
      background: #306cd5 !important;
    }

    ::v-deep( .el-sub-menu__icon-arrow) {
      color: white;
    }

    &.is-opened {
      ::v-deep(.el-icon-arrow-right):before {
        content: "\00D7";
        font-size: 16px;
      }
    }
  }

  &.is-opened {
    ::v-deep(.el-icon-arrow-right):before {
      content: "\00D7";
      font-size: 16px;
    }
  }
}

.mobile-sub-menus-item--inner {
  margin: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  padding: 0 0 0 16px !important;
  height: 51px !important;
  line-height: 23px !important;
  display: flex;
  align-items: center;

  a {
    color: #333333 !important;
    opacity: 1;
    width: 100%;
    display: inline-block;
  }

  a:hover {
    opacity: 0.7;
  }
}
.header-sticky {
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1) !important;
}
</style>
