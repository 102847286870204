<template>
  <el-dialog
    v-model:visible="dialogVisible"
    width="100%"
    :fullscreen="true"
    :before-close="handleClose"
  >
    <div class="dialog-header">
      <div
        class="header-close-icon"
        @click="$emit('close-dialog')"
      >
        <img
          src="../assets/community_index/close-button.png"
          alt="FariaOne"
          style="height: 20px;width: 20px;"
        >
      </div>
      <div class="header-share-button">
        SHARE
      </div>
    </div>
    <div>
      content
    </div>
  </el-dialog>
</template>

<script>
import "element-plus/theme-chalk/display.css";
import "element-plus/theme-chalk/display.css";

export default {
  components: {},
  props: {
    propDialogVisible: {
      type: Boolean,
      default: () => false
    },
    propBadgeCategories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogVisible: this.propDialogVisible
    };
  },
  watch: {
    propDialogVisible: {
      handler(newValue) {
        this.dialogVisible = newValue;
      }
    }
  },
  methods: {
    handleClose(done) {
      this.$emit("close-dialog");
      done();
    }
  }
};
</script>

<style src="element-plus/theme-chalk/display.css"></style>
<style lang="scss" scoped>
@import "../css/default-settings";
::v-deep(.el-dialog) {
  display: flex;
  flex-direction: column;
}

::v-deep(.el-dialog__body) {
  padding: 0;
}

::v-deep(.el-dialog__header) {
  display: none;
}

.dialog-header {
  display: flex;
  margin: 24px 32px 40px 32px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1200px) {
    margin: 24px 16px 40px 16px;
  }
}

.header-share-button {
  padding: 6px 12px 6px 8px;
  background: #1ecd6e;
  box-shadow: 0px 3px 5px rgba(50, 50, 93, 0.15);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.header-close-icon {
  display: flex;
  cursor: pointer;
}

</style>
