<template>
  <el-col
    :sm="24"
    :lg="7"
    :md="9"
    :style="badgesColumnStyle"
    class="badges-column"
  >
    <el-row>
      <el-col>
        <div
          class="badges-container"
          :style="badgeContainerClass"
        >
          <button
            class="accordion"
            @click="handleAccordion"
          >
            Filter by Badge
          </button>
          <span
            class="panel"
            style="display: block"
          >
            <div class="badges-search">
              <el-input
                v-model="searchText"
                placeholder="Search badges"
              >
                <template #prefix>
                  <el-icon class="el-input__icon"><search /></el-icon>
                </template>
                <template #suffix>
                  <img
                    v-if="searchText"
                    src="../assets/community_index/close-button.png"
                    alt="close_button"
                    style="height: 20px;width: 20px;"
                    @click="searchText = ''"
                  >
                </template></el-input>
            </div>
            <div v-if="noBadgeResult">No Matches</div>
            <div
              v-for="(
                { name, header, icon, badges, color }, index
              ) in badgeCategories"
              v-else
              :key="name"
              class="badge-item-group"
            >
              <div class="badge-item-title-container">
                <div class="badge-item-icon-image">
                  <img
                    :src="icon"
                    style="height: 24px; width: 24px"
                    alt="FariaOne"
                  >
                </div>
                <div
                  class="badge-item-title"
                  :style="`color: ${color}`"
                >
                  {{ header }}
                </div>
              </div>
              <div class="badge-items-list-container">
                <el-checkbox-group
                  v-model="badgeCategories[index].selectedBadges"
                >
                  <div
                    v-for="badge in badges"
                    :key="badge.name"
                    class="badge-items-list-container--inner"
                  >
                    <el-checkbox :label="badge.name">
                      <div class="badge-item-checkbox-container">
                        <div>
                          <img
                            :src="badge.icon"
                            style="height: 28px; width: 28px"
                            alt="FariaOne"
                          >
                        </div>
                        <div class="badge-item-checkbox-title">
                          {{ badge.name }}
                        </div>
                      </div>
                    </el-checkbox>
                    <div class="badge-item-checkbox-info">
                      <img
                        src="../assets/community_index/icon-info.svg"
                        alt="FariaOne"
                        style="height: 15px; width: 15px"
                        @click="openBadgeInfoModal(badge)"
                      >
                    </div>
                  </div>
                </el-checkbox-group>
              </div>
              <div class="badge-item-group-separator" />
            </div>
          </span>
        </div>
        <div
          class="regions-container"
          :style="regionsContainerClass"
        >
          <button
            class="accordion"
            @click="handleAccordion"
          >
            Filter by Region
          </button>
          <span
            class="panel"
            style="display: block"
          >
            <div class="regions-list-container">
              <el-checkbox-group v-model="selectedRegions">
                <div
                  v-for="region in categoryRegions"
                  :key="region"
                  class="regions-list-container--inner"
                >
                  <el-checkbox :label="region">
                    <div class="badge-item-checkbox-container">
                      <div class="badge-item-checkbox-title">
                        {{ formatRegion(region) }}
                      </div>
                    </div>
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </span>
        </div>
        <div
          v-if="propSearchResultText"
          class="show-members-btn"
          @click="$emit('close-dialog')"
        >
          {{ propSearchResultText.filterModalBtnText }}
        </div>
      </el-col>
    </el-row>
    <CommunityBadgeInfoModal
      :prop-dialog-visible="badgesModalDialogVisible"
      :prop-badge-categories="badgeCategories"
      @close-dialog="badgesModalDialogVisible = false"
    />
  </el-col>
</template>

<script>
import "element-plus/theme-chalk/index.css";
import "element-plus/theme-chalk/display.css";
import CommunityBadgeInfoModal from "./CommunityBadgeInfoModal.vue";

export default {
  components: {
    CommunityBadgeInfoModal,
  },
  props: {
    propDialogVisible: {
      type: Boolean,
      default: false,
    },
    propBadgeCategories: {
      type: Array,
      default: () => [],
    },
    propCategoryRegions: {
      type: Array,
      default: () => [],
    },
    propCertificationPath: {
      type: String,
      default: () => "",
    },
    propCommunityPath: {
      type: String,
      default: () => "",
    },
    propSelectedRegions: {
      type: Array,
      default: () => [],
    },
    propOnMobile: {
      type: Boolean,
      default: () => false,
    },
    propSearchResultText: {
      type: Object,
      default: () => {}
    }
  },
  emits: ["badges-search-text-changed", "search-params-changed", "close-dialog"],
  data() {
    return {
      selectedRegions: this.propSelectedRegions,
      badgeCategories: this.propBadgeCategories,
      categoryRegions: this.propCategoryRegions,
      searchText: "",
      badgesModalDialogVisible: false,
    };
  },
  computed: {
    badgeContainerClass() {
      return this.propOnMobile
        ? { padding: "0px", boxShadow: "none" }
        : { padding: "40px 30px 0" };
    },
    regionsContainerClass() {
      return this.propOnMobile
        ? { padding: "20px 0px 40px 0px", boxShadow: "none" }
        : { padding: "20px 30px 40px 28px" };
    },
    noBadgeResult() {
      return this.searchText && this.badgeCategories.length === 0;
    },
    badgesColumnStyle() {
      return this.propOnMobile ? { marginRight: "0px" } : "";
    },
  },
  watch: {
    badgeCategories: {
      handler() {
        this.$emit("search-params-changed", {
          selectedRegions: this.selectedRegions,
          badgeCategories: this.badgeCategories,
        });
      },
      deep: true,
    },
    selectedRegions: {
      handler() {
        this.$emit("search-params-changed", {
          selectedRegions: this.selectedRegions,
          badgeCategories: this.badgeCategories,
        });
      },
      deep: true,
    },
    propBadgeCategories: {
      handler() {
        this.badgeCategories = this.propBadgeCategories;
      },
      deep: true,
    },
    propSelectedRegions: {
      handler() {
        this.selectedRegions = this.propSelectedRegions;
      },
      deep: true,
    },
    searchText: {
      handler() {
        this.$emit("badges-search-text-changed", this.searchText);
      },
    },
  },
  methods: {
    handleAccordion(e) {
      const btn = e.srcElement;
      btn.classList.toggle("active");
      const panelElement = btn.nextElementSibling;
      panelElement.style.display =
        panelElement.style.display === "block" ? "none" : "block";
    },
    formatRegion(region) {
      return region === "Asia-Pacific" ? "Asia Pacific" : region;
    },
    openBadgeInfoModal(badge) {
      var identifier = (badge.category + "-" + badge.name)
        .toLowerCase()
        .replace(/ /g, "-");
      this.openUrl(this.propCertificationPath + "/badges/" + identifier);
    },
    openUrl(url, target = "_self") {
      window.open(url, target);
    },
  },
};
</script>

<style src="element-plus/theme-chalk/index.css"></style>
<style lang="scss" scoped>
@import "../css/default-settings";

.badges-column {
  margin-right: 40px;
}

.badges-container {
  border-radius: 24px 24px 0px 0px;
  background: white;
  border: none;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 1px rgba(51, 109, 205, 0.3),
    0px 9px 14px -4px rgba(51, 109, 205, 0.1);
}

.badges-title {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
}

.accordion {
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -0.05em;
  color: #333333;
  margin-bottom: 32px;
  background-color: white;
  cursor: pointer;
  padding: 0 0 31px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  transition: 0.4s;

  &.active {
    margin-bottom: 0px;
  }
}

.accordion:after {
  content: url("../assets/community_index/collapse-icon.svg");
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: url("../assets/community_index/expand-icon.svg");
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.panel {
  transition: max-height 0.2s ease-out;
}

.badges-search {
  margin-bottom: 24px;

  ::v-deep(.el-input__wrapper) {
    border-radius: 24px;
  }

  ::v-deep(.el-input__prefix) {
    left: 12px;
    top: 10px !important;
    cursor: pointer;
  }

  ::v-deep(.el-input__suffix) {
    right: 10px;
    top: 10px !important;
    cursor: pointer;
  }

  ::v-deep(.el-input__inner) {
    height: 40px;
  }
}

.badge-item-group {
  display: flex;
  flex-direction: column;
}

.badge-item-title-container {
  display: flex;
  column-gap: 8px;
  align-items: center;
  margin-bottom: 16px;
}

.badge-item-group-separator {
  border-bottom: 1px solid #e9f2ff;
  margin-top: 40px;
  margin-bottom: 22px;
}

.badge-item-icon-image {
  display: flex;
}

.badge-item-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.05em;
  display: flex;
}

.badge-item-info {
  margin-left: auto;
}

.show-members-btn {
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  padding: 18px 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: left;
  background: #478CFE;
  cursor: pointer;
  color: #fff;
  border: none;
  transition: all 0.6s cubic-bezier(.165,.84,.44,1);
  justify-content: center;
}

::v-deep(.el-checkbox__inner) {
  border: 2px solid #d8d8d8;
  height: 19px;
  width: 19px;
  min-height: 19px;
  min-width: 19px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 600;
}

::v-deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
  border: 2px solid #d8d8d8;
}

::v-deep(.el-checkbox.is-checked .el-checkbox__inner),
::v-deep(
    .el-checkbox.is-checked .el-checkbox__input.is-focus .el-checkbox__inner
  ) {
  background: #478cfe;
  border: 2px solid #478cfe;
}

::v-deep(.el-checkbox__inner)::after {
  border-bottom: 1.5px solid #ffffff;
  border-left: none;
  border-right: 1.5px solid #ffffff;
  border-top: none;
  content: "";
  height: 60%;
  left: 5px;
  top: 1px;
  width: 28%;
}

.badge-items-list-container {
  & > .el-checkbox-group {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 16px;

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  }

  ::v-deep(.el-checkbox__input) {
    display: block;
    margin-right: 0px;
  }

  ::v-deep(.el-checkbox__label) {
    width: 100%;
    display: flex;
    padding-left: 0px;
    margin-left: 0px;
  }

  ::v-deep(.el-checkbox) {
    display: flex;
    column-gap: 7px;
  }
}

.badge-item-checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
}

.badge-items-list-container--inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge-item-checkbox-title {
  width: 185px;
  height: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.badge-item-checkbox-info {
  margin-left: auto;
  cursor: pointer;
}

.regions-container {
  background: white;
  border-radius: 0px 0px 24px 24px;
  border: none;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 1px rgba(51, 109, 205, 0.3),
    0px 9px 14px -4px rgba(51, 109, 205, 0.1);
}

.regions-title {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
}

.regions-title-name {
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -0.05em;
  color: #333333;
}

.regions-list-container {
  & > .el-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 16px;

    label {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  ::v-deep(.el-checkbox__input) {
    display: block;
  }

  ::v-deep(.el-checkbox__label) {
    width: 100%;
    display: flex;
    padding-left: 0px;
    margin-left: 8px;
  }

  ::v-deep(.el-checkbox) {
    display: flex;
  }
}

.regions-list-container--inner {
  display: flex;
  align-items: center;
}
</style>
