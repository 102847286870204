export default {
  data() {
    return {
      badgesOrder: [
        "SchoolsTechnology-Contributor",
        "ManageBac-Community Ambassador",
        "ManageBac-Community Pioneer",
        "ManageBac-Subject Leader",
        "ManageBac-Training Academy",
        "ManageBac-Certified Educator",
        "OpenApply-Contributor",
        "SchoolsBuddy-Contributor",
        "Pamoja-SBC Ambassador",
        "Pamoja-Educator",
        "Pamoja-Thought Leader",
        "OSC-Teacher",
        "OSC-Tutor",
        "OSC-Contributor",
        "OSC-Content Leader",
        "Atlas-Contributor",
        "MiniPD-Coach",
        "MiniPD-Course Designer"
      ]
    };
  },
  methods: {
    sortMemberBadges(badges) {
      return this.sortFn(badges, this.badgesOrder);
    },
    sortFn(itemsArray, sortingArr) {
      return itemsArray.sort(
        (a, b) =>
          sortingArr.indexOf(`${a.category}-${a.name}`) -
          sortingArr.indexOf(`${b.category}-${b.name}`)
      );
    },
    createBadgeLink(certificationPath, badge) {
      const identifier = (badge.category + "-" + badge.name)
        .toLowerCase()
        .replace(/ /g, "-");
      return `${certificationPath}/badges/${identifier}`;
    },
    openUrl(url, args = "_self") {
      window.open(url, args);
    }
  }
};
