<template>
  <el-footer class="el-footer">
    <div class="footer-links">
      <el-row
        :gutter="20"
        :style="{
          marginLeft: propFooterMargin + 'px',
          marginRight: propFooterMargin + 'px',
          width: propWidth,
          'max-width': propMaxWidth
        }"
      >
        <el-col
          :xs="24"
          :sm="12"
          :md="6"
          :lg="5"
        >
          <FooterLinksGroup :prop-links-group="fariaOneLinks" />
          <FooterLinksGroup :prop-links-group="admissionLinks" />
        </el-col>
        <el-col
          :xs="24"
          :sm="12"
          :md="6"
          :lg="5"
        >
          <FooterLinksGroup :prop-links-group="learningLinks" />
        </el-col>
        <el-col
          :xs="24"
          :sm="12"
          :md="6"
          :lg="5"
        >
          <FooterLinksGroup :prop-links-group="schoolToHomeLinks" />
          <FooterLinksGroup :prop-links-group="studyLinks" />
        </el-col>
        <el-col
          :xs="24"
          :sm="12"
          :md="6"
          :lg="5"
        >
          <FooterLinksGroup :prop-links-group="onlineCourseLinks" />
          <FooterLinksGroup :prop-links-group="supportCenterLinks" />
        </el-col>
        <el-col
          :xs="24"
          :sm="12"
          :md="4"
        >
          <FooterLinksGroup :prop-links-group="aboutFariaLinks" />
          <div>
            <button
              class="subscribe-button"
              @click="openUrl('https://www.faria.org/?title=digest-intake')"
            >
              Subscribe
            </button>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="footer-copyright">
      <el-row
        :style="{
          marginLeft: propFooterMargin + 'px',
          marginRight: propFooterMargin + 'px',
          width: propWidth,
          'max-width': propMaxWidth,
          display: 'flex',
          'align-items': 'center'
        }"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="11"
          class="copyright-wrapper"
        >
          <p>
            {{ copyrightText }}
          </p>
        </el-col>
        <el-col
          :xs="12"
          :sm="6"
          :md="2"
          :lg="3"
          class="copyright-links link-divider"
        >
          <a href="https://www.faria.org/terms-of-service">Terms</a>
        </el-col>
        <el-col
          :xs="12"
          :sm="6"
          :md="2"
          :lg="3"
          class="copyright-links link-divider"
        >
          <a href="https://www.faria.org/terms-of-service">Privacy</a>
        </el-col>
        <el-col
          :xs="12"
          :sm="6"
          :md="2"
          :lg="3"
          class="copyright-links link-divider"
        >
          <a href="https://www.faria.org/secure">Security</a>
        </el-col>
        <el-col
          :xs="12"
          :sm="6"
          :md="4"
          :lg="2"
          class="copyright-links"
        >
          <a href="https://www.faria.org/">
            <img
              src="../assets/community/global.png"
              style="height: 20px; vertical-align: middle; margin-right: 8px; margin-top: -3px;"
            >
            Global
          </a>
        </el-col>
      </el-row>
    </div>
  </el-footer>
</template>
<script>
import FooterLinksGroup from "./FooterLinksGroup.vue";
import CommunityMixin from "../mixins/CommunityMixin.js";

export default {
  name: "CommunityFooter",
  components: { FooterLinksGroup },
  mixins: [CommunityMixin],
  props: {
    propFooterMargin: {
      type: Number,
      default: () => null
    },
    propWidth: {
      type: String,
      default: () => null
    },
    propMaxWidth: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      fariaOneLinks: {
        title: {
          name: "FariaOne",
          url: "https://www.faria.org/"
        },
        links: [
          {
            name: "Systems",
            url: "https://www.faria.org/"
          }
        ]
      },
      admissionLinks: {
        title: {
          name: "Admissions",
          url: "https://openapply.com/"
        },
        links: [
          {
            name: "International Schools",
            url: "https://openapply.com/international-schools"
          },
          {
            name: "Independent Schools",
            url: "https://openapply.com/independent-schools"
          },
          {
            name: "New Schools",
            url: "https://openapply.com/new-schools"
          },
          {
            name: "Greater China",
            url: "https://openapply.com/greater-china"
          },
          {
            name: "School Groups",
            url: "https://openapply.com/school-groups"
          },
          {
            name: "CRM",
            url: "https://www.openapply.com/crm-features"
          },
          {
            name: "Admissions Benchmarking",
            url: "https://openapply.com/admissions-benchmarking"
          },
          {
            name: "Admissions Bulletin",
            url: "https://openapply.com/bulletin"
          },
          {
            name: "Admissions Testing (IDAT)",
            url: "https://openapply.com/idat"
          },
          {
            name: "Schools Directory",
            url: "https://search.openapply.com/for_schools"
          }
        ]
      },
      learningLinks: {
        title: {
          name: "Learning",
          url: "https://www.faria.org/learning"
        },
        links: [
          {
            url: "https://www.managebac.com/diploma",
            name: "IB Diploma"
          },
          {
            url: "https://www.managebac.com/career-programme",
            name: "IB Career-Related"
          },
          {
            url: "https://www.managebac.com/ib-myp",
            name: "IB Middle Years"
          },
          {
            url: "https://www.managebac.com/ib-pyp",
            name: "IB Primary Years"
          },
          {
            url: "https://www.managebac.com/multicurricula",
            name: "IGCSE & A Levels"
          },
          {
            url: "https://www.managebac.com/multicurricula",
            name: "Advanced Placement"
          },
          {
            url: "https://www.managebac.com/multicurricula",
            name: "Curriculum First"
          },
          {
            url: "https://www.managebac.com/mobile",
            name: "Mobile"
          },
          {
            url: "https://www.managebac.com/assessprep",
            name: "Online Exams"
          },
          {
            url: "https://www.managebac.com/integrated-remote-learning",
            name: "Remote Learning"
          },
          {
            url: "https://www.faria.org/learning/buildyourown/",
            name: "Atlas"
          }
        ]
      },
      schoolToHomeLinks: {
        title: {
          name: "School-to-Home",
          url: "https://www.faria.org/schoolhome/"
        },
        links: [
          {
            name: "Activities Management",
            url: "https://www.faria.org/schoolhome/activities-management/"
          },
          {
            name: "Micropayments & Billing",
            url: "https://www.faria.org/schoolhome/online-payments/"
          },
          {
            name: "Events, Sports & Trips",
            url: "https://www.faria.org/schoolhome/event-management/"
          },
          {
            name: "Parent Teacher Conferences",
            url: "https://www.faria.org/schoolhome/parent-conferences/"
          },
          {
            name: "Transport System",
            url: "https://www.faria.org/schoolhome/school-transport-system"
          },
          {
            name: "Parent Communications",
            url:
              "https://www.faria.org/schoolhome/parent-communications-software/"
          }
        ]
      },
      studyLinks: {
        title: {
          name: "Study",
          url: "https://www.faria.org/study/"
        },
        links: [
          {
            name: "OSC Study App",
            url: "https://www.faria.org/study/"
          },
          {
            name: "OSC Revision Courses",
            url: "https://www.faria.org/study/courses"
          },
          {
            name: "OSC Tutor",
            url: "https://www.faria.org/study/tutor"
          }
        ]
      },
      onlineCourseLinks: {
        title: {
          name: "Online Courses",
          url: "https://www.faria.org/online/"
        },
        links: [
          {
            name: "IB Online Courses",
            url: "https://www.faria.org/online/ib"
          },
          {
            name: "Homeschool",
            url: "https://wolseyhalloxford.org.uk/"
          }
        ]
      },
      supportCenterLinks: {
        title: {
          name: "Support Centre",
          url: "https://www.faria.org/study/",
          isText: true
        },
        links: [
          {
            name: "Help & Support",
            url: "https://help.faria.org/hc/en-us"
          },
          {
            name: "Events, PD & Workshops",
            url: "https://events.fariaedu.com/"
          }
        ]
      },
      aboutFariaLinks: {
        title: {
          name: "About Faria",
          url: "https://www.faria.org/study/",
          isText: true
        },
        links: [
          {
            name: "About Faria",
            url: "https://www.faria.org/about"
          },
          {
            name: "Leadership",
            url: "https://www.faria.org/team"
          },
          {
            name: "Contact",
            url: "https://www.faria.org/office-locations"
          },
          {
            name: "Careers at Faria",
            url: "https://www.faria.org/careers"
          },
          {
            name: "Newsroom",
            url: "https://www.faria.org/news"
          },
          {
            name: "Writing",
            url: "https://www.faria.org/writing"
          },
          {
            name: "Terms",
            url: "https://www.faria.org/terms-of-service"
          },
          {
            name: "Security & Data Protection",
            url: "https://www.faria.org/secure",
            showSubscribeButton: true
          }
        ]
      },
      mobileMenuLinks: [
        {
          title: "Learning",
          index: "learning-mobile-sub-menu",
          menuLinks: [
            {
              href: "https://www.faria.org/learning/diploma",
              text: "Diploma",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/dp-icon-1.svg"
            },
            {
              href: "https://www.faria.org/learning/career",
              text: "Career-Related",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/cp-icon.png"
            },
            {
              href: "https://www.faria.org/learning/middle",
              text: "Middle Years",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/myp-icon.svg"
            },
            {
              href: "https://www.faria.org/learning/primary",
              text: "Primary Years",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/pyp-icon.svg"
            },
            {
              href: "https://www.faria.org/learning/c1",
              text: "Cambridge",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/igcse-icon.svg"
            },
            {
              href: "https://www.faria.org/learning/c1",
              text: "Advanced Level",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/A-Levels.svg"
            },
            {
              href: "https://www.faria.org/learning/c1",
              text: "AP",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/ap-icon.svg"
            },
            {
              href: "https://www.faria.org/learning/buildyourown",
              text: "Build-your-Own Curriculum",
              imgUrl: "https://www.faria.org/wp-content/uploads/2021/03/c1.png"
            },
            {
              href: "https://www.faria.org/learning/mobile",
              text: "Mobile",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/7B740977-FE7A-4F5F-87BD-4188E3702D8D.png"
            },
            {
              href: "https://www.faria.org/learning/exams",
              text: "AssessPrep",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/6B8AA6C5-0FD4-43C8-A8F2-F662805A71F4.png"
            }
          ]
        },
        {
          title: "Admissions",
          index: "admissions-mobile-sub-menu",
          menuLinks: [
            {
              href: "https://www.faria.org/admissions/management",
              text: "Admissions Management",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/Admissions.png"
            },
            {
              href: "https://www.faria.org/admissions/crm",
              text: "CRM",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/oa-crm-icon.png"
            },
            {
              href: "https://www.faria.org/admissions/directory",
              text: "Schools Directory",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/oa-directory-ico.png"
            },
            {
              href: "https://www.faria.org/admissions/testing",
              text: "Admissions Testing",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/idat-logo.png"
            }
          ]
        },
        {
          title: "School-to-Home",
          index: "school-to-home-mobile-sub-menu",
          menuLinks: [
            {
              href: "https://www.faria.org/schoolhome/activities-management",
              text: "Activities Management",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/icon-active-management.png"
            },
            {
              href: "https://www.faria.org/schoolhome/online-payments",
              text: "Micropayments & Billing",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/icon-micropayment.png"
            },
            {
              href: "https://www.faria.org/schoolhome/event-management",
              text: "Events, Sports & Trips",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/Event-Sport-And-Trip-Management-1.png"
            },
            {
              href: "https://www.faria.org/schoolhome/parent-conferences",
              text: "Parent Teacher Conferences",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/Parent-Teacher-Conferences-1.png"
            },
            {
              href: "https://www.faria.org/schoolhome/school-transport-system",
              text: "Transport & Dismissal",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/Transport-System-1.png"
            },
            {
              href:
                "https://www.faria.org/schoolhome/parent-communications-software",
              text: "Parent Communications",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/Parent-Communications-1.png"
            },
            {
              href: "https://www.faria.org/schoolhome/school-bookings",
              text: "School Bookings",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/05/Booking-SB-ico.png"
            },
            {
              href: "https://www.faria.org/schoolhome/mobile",
              text: "Mobile",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2022/01/icon-mobile.png"
            }
          ]
        },
        {
          title: "Online Courses",
          index: "online-courses-mobile-sub-menu",
          menuLinks: [
            {
              href: "https://www.faria.org/online/ib",
              text: "IB Diploma",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/214E3799-D07E-4207-A64E-7FFBD7E288B9.png"
            },
            {
              href: "https://www.faria.org/online/lesson-suite",
              text: "IB Diploma Lesson Suite",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/Group-64.png"
            },
            {
              href: "https://pamojaeducation.com/about",
              text: "About Pamoja",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/Pamoja-icon@2x.png"
            }
          ]
        },
        {
          title: "Study",
          index: "study-mobile-sub-menu",
          menuLinks: [
            {
              href: "https://www.faria.org/online/ib",
              text: "IB Diploma",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/214E3799-D07E-4207-A64E-7FFBD7E288B9.png"
            },
            {
              href: "https://www.faria.org/online/lesson-suite",
              text: "IB Diploma Lesson Suite",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/Group-64.png"
            },
            {
              href: "https://pamojaeducation.com/about",
              text: "About Pamoja",
              imgUrl:
                "https://www.faria.org/wp-content/uploads/2021/03/Pamoja-icon@2x.png"
            }
          ]
        }
      ],
      mobileLoginMenuLinks: [
        {
          href: "https://www.managebac.com/login",
          text: "ManageBac"
        },
        {
          href: "http://openapply.com/login",
          text: "OpenApply"
        },
        {
          href: "https://www.schoolsbuddy.com/login",
          text: "SchoolsBuddy"
        },
        {
          href: "https://pamojaeducation.com/login",
          text: "Pamoja"
        },
        {
          href: "https://oxfordstudycourses.openapply.com/parents/sign_in",
          text: "OSC"
        }
      ]
    };
  },
  computed: {
    copyrightText() {
      return `© ${new Date().getFullYear()} Faria Education Group Limited. All Rights Reserved.`;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-footer {
  width: 100%;
  height: 100% !important;
  padding: 0;
  align-items: start;
  text-align: start;
  background-color: #fff;
  border-top-width: 1px;
  border-top-color: #f5f5ff;
  margin-top: 40px;
  font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
}

.footer-links {
  background-color: #fff;
  border-top: 1px solid #f5f5ff;

  ::v-deep(.el-row) {
    padding-top: 30px;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  ::v-deep(.el-col) {
    padding-left: 0px !important;
  }
}
.footer-copyright {
  background-color: #f8fbfe !important;
  font-size: 14px;
  font-weight: 500;

  ::v-deep(.el-row) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 767px) {
    ::v-deep(.el-col) {
      height: 26px !important;
    }
  }
}

.copyright-wrapper {
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: 62px !important;

  @media screen and (max-width: 991px) {
    margin-bottom: 30px;
  }
}

.copyright-links {
  padding-left: 0px !important;
  padding-right: 0px !important;

  a {
    color: #333333 !important;
  }
  a:hover {
    color: #478cfe !important;
  }
}

.link-divider a:after {
  @media screen and (min-width: 768px) {
    content: "";
    border-right: 1px solid #d9e7f7;
    left: 25%;
    position: relative;
  }
}

.subscribe-button {
  cursor: pointer;
  background: #306cd5;
  text-align: center;
  padding: 12px 28px 12px !important;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12.6px;
  color: #ffffff;
  border: none;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: rgba(34, 45, 57, 0.02) 1px 0px 0px inset;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  text-transform: uppercase;
}

.subscribe-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  background: #478cfe;
}
</style>
