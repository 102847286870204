<template>
  <div class="community-members-selected-search-item">
    <div class="search-item-info">
      <div class="search-item-type">
        {{ searchItemType }}
      </div>
      <div class="search-item-desc">
        <div
          v-if="isSearchItemBadge"
          class="search-item-desc-logo"
        >
          <img
            :src="propSearchItem.icon"
            style="height: 24px;width: 24px;"
            alt="FariaOne"
          >
        </div>
        <div class="search-item-desc-name">
          {{ searchItemDscription }}
        </div>
      </div>
    </div>
    <div class="search-item-close-icon">
      <img
        src="../assets/community_index/close-button.png"
        alt="FariaOne"
        style="height: 32px;width: 32px;"
        @click="$emit('remove-filter-tile', propSearchItem)"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propSearchItem: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isSearchItemBadge() {
      return this.propSearchItem.type === "badge";
    },
    searchItemType() {
      return this.isSearchItemBadge ? "Badge" : "Region";
    },
    searchItemDscription() {
      return this.isSearchItemBadge
        ? this.propSearchItem.name
        : this.propSearchItem.region === "Asia-Pacific"
        ? "Asia Pacific"
        : this.propSearchItem.region;
    }
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
@import "../css/default-settings";
.community-members-selected-search-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 20px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 40px;
  height: 56px;
  flex: 0 0 auto;
}

.search-item-type {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: #9f9f9f;
}

.search-item-desc {
  display: flex;
  column-gap: 8px;
}

.search-item-close-icon {
  cursor: pointer;
}
</style>
