<template>
  <div
    ref="breadcrumb_navigation"
    class="breadcrumb-container"
  >
    <div
      :style="commonMarginClass"
      class="breadcrumb-inner"
    >
      <div
        v-for="(crumb, index) in propBreadCrumbs"
        :key="index"
        class="breadcrumb-item-badge"
      >
        <img
          v-if="index !== 0"
          :src="getImgUrl('community/chevron-right.png')"
          alt="chevron-right"
          class="chevron-right-icon"
        >
        <a
          v-if="crumb.href"
          :href="crumb.href"
        >
          <img
            v-if="crumb.icon"
            :src="crumb.icon"
            :alt="crumb.name"
            class="breadcrum-icon"
          >
          <span class="crumb-name">{{ crumb.name }}</span>
        </a>

        <p v-else>
          <img
            v-if="crumb.icon"
            :src="crumb.icon"
            :alt="crumb.name"
            class="breadcrum-icon"
          >
          <span class="crumb-name non-link">{{ crumb.name }}</span>
        </p>
      </div>

      <div
        v-if="false"
        class="demo-button"
      >
        <a
          href="https://www.managebac.com/schedule-demo"
          target="_blank"
        >
          Schedule a Demo</a>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import "element-plus/theme-chalk/display.css";
import "element-plus/theme-chalk/display.css";
import screenSizeMixin from "../mixins/screenSizeMixin.js";

export default {
  name: "CommunityBreadCrumbs",
  mixins: [screenSizeMixin],
	props: {
    propBreadCrumbs: {
      type: Array,
      default: () => []
    },
	},
  computed: {
    commonMarginClass() {
      return {
        marginLeft: `${this.margin}px`,
        marginRight: `${this.margin}px`
      };
    },
  },
  mounted() {
    this.handleScrolling();
  },
  unmounted() {
    window.removeEventListener("scroll");
  },
	methods: {
    handleScrolling() {
      if (this.windowWidth > 980) {
        window.addEventListener("scroll", () => {
          const scrollPosition = window.pageYOffset;
          const breadCrumEl = this.$refs.breadcrumb_navigation;

          if (scrollPosition >= 45) {
            breadCrumEl.classList.add("breadcrumb-sticky");
          } else {
            breadCrumEl.classList.remove("breadcrumb-sticky");
          }
        });
      }
    },
    getImgUrl(imagePath) {
      return require(`../assets/${imagePath}`);
    },
	},
}
</script>

<style lang="scss" scoped>
@import "../css/default-settings";

.demo-button {
  a {
    background: #478cfe;
    color: #fff;
    transition: all 0.4s ease-in-out;
    border-radius: 5px;
    display: inline-block;
    font-size: 0.9em;
    line-height: 1.4em;
    padding: 8px 16px;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }

  a:hover {
    transform: translateY(-1px);
    color: #fff;
  }

  @include for-tablet-and-mobile {
    display: none;
  }
}

.breadcrumb-container {
  background: #f6f9fc;
  height: 50px;
  padding: 8px 0px;
  display: flex;
  align-items: center;
  z-index: 10;
  top: 74px;
  width: 100%;
  font-family: "Open Sans", "helvetica neue", Helvetica, Arial, sans-serif;
  overflow: hidden;

  @include for-desktop-content {
    position: fixed;
  }

  @media screen and (max-width: 1178px) {
    height: 69px;
  }

  img {
    width: 22.3906px;
    height: 22.3906px;
    border-radius: 4px;
  }

  &.breadcrumb-sticky {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  }
}

.breadcrumb-inner {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;

  div:last-child {
    flex-grow: 1;

    a {
      display: block;
      width: 90%;
    }
  }
}

.breadcrumb-item-badge {
  display: flex;
  align-items: center;
  margin-top: 4px;

  a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 100;
    color: #9f9f9f !important;
  }

  .chevron-right-icon {
    width: 16px;
    height: 16px;
    margin-top: 4px;
  }

  .breadcrum-icon {
    margin-right: 6px;
  }

  .crumb-name {
    min-width: 74px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #9f9f9f;

    &.non-link {
      color: #333;
    }
  }

  .crumb-name:hover {
    color: #478cfe;
    cursor: pointer;

    &.non-link:hover {
      color: #333;
      cursor: auto;
    }
  }
}
</style>
