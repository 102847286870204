import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import 'element-plus/theme-chalk/index.css'
import 'element-plus/dist/index.css'
import { ObserveVisibility } from 'vue-observe-visibility'
import CommunityIndexApp from "../views/CommunityIndexApp.vue"
import "../css/default-settings.scss"

addEventListener("load", () => {
  document.querySelectorAll(".vue-component").forEach((element) => {
    const railsProps = JSON.parse(element.dataset.railsData)

    const app = createApp(CommunityIndexApp, {
        ...railsProps
    })


    // vue observevisibility
    app.directive('observe-visibility', {
      beforeMount: (el, binding, vnode) => {
        vnode.context = binding.instance;
        ObserveVisibility.bind(el, binding, vnode);
      },
      update: ObserveVisibility.update,
      unmounted: ObserveVisibility.unbind,
    });

    // register all element plus  icons
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      app.component(key, component)
    }

    app.use(ElementPlus)
    app.mount(element)
  })
})