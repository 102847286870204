<template>
  <div>
    <div class="top-banner">
      <div
        class="commuinity-container"
        :style="commonMarginClass"
      >
        <div class="community-tabs">
          <a
            v-for="(tab, index) in communityTabs"
            :key="index"
            :href="tab.url"
            class="community-tab-item"
          >{{ tab.label }}</a>
        </div>
        <div class="community-tabs-mobile">
          <el-select
            :key="dummyKey"
            v-model="selectedCommunityMobileTab"
            placeholder="Select"
            :teleported="false"
            @change="handleMobileCommunityTabClick"
          >
            <el-option
              v-for="(item, index) in communityTabs"
              :key="item.value"
              :label="item.label"
              :value="index"
            />
          </el-select>
        </div>
        <div class="community-logo">
          <img
            src="../assets/community_index/community-hands.png"
            alt="FariaOne"
          >
        </div>
        <div class="community-title">
          Faria Community
        </div>
        <div class="community-sub-title">
          <p>
            Discover a wealth of opportunities to learn and connect with
            like-minded individuals.
          </p>
          <p>
            Join fellow leading educators from all over the world who possess
            demonstrable pedagogical and product expertise.
          </p>
        </div>
        <div class="community-mission-statement">
          <div class="mission-btn">
            <a
              :href="`${currentUrl}/mission`"
            >Read Our Mission Statement
              <img
                src="../assets/community_index/chevron_right.svg"
                alt="chevron_right"
              >
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- desktop -->
    <div
      :style="commonMarginClass"
      class="desktop-community-content"
    >
      <el-row justify="center">
        <CommunityBadgesAndRegionSearchMenu
          :prop-badge-categories="badgeCategories"
          :prop-category-regions="categoryRegions"
          :prop-selected-regions="selectedRegions"
          :prop-community-path="propCommunityPath"
          :prop-certification-path="propCertificationPath"
          @search-params-changed="handleParamsChanged"
          @badges-search-text-changed="handleBadgesSearch"
        />
        <el-col
          :sm="24"
          :md="12"
          :lg="16"
        >
          <el-row>
            <el-col>
              <div class="community-members-container">
                <div class="community-members-title-container">
                  <div class="community-members-title-text">
                    <span
                      v-if="searchResultText.text !== noMatchHtml"
                      class="members-count"
                    >{{ searchResultText.count }}</span><span v-html="searchResultText.text" />
                  </div>
                  <div
                    v-if="false"
                    class="community-members-title-icons-container"
                  >
                    <div class="community-members-title-asc-sort">
                      <img
                        src="../assets/community_index/asc-sort-icon.png"
                        alt="FariaOne"
                        style="height: 18px; width: 18px"
                      >
                    </div>
                    <div class="community-members-title-alpha-sort">
                      <img
                        src="../assets/community_index/alpha-sort-icon.png"
                        alt="FariaOne"
                        style="height: 18px; width: 18px"
                      >
                    </div>
                  </div>
                </div>
                <div
                  v-if="isSearchTileDataAvailable"
                  class="community-members-selected-search-items"
                >
                  <CommunityIndexSearchTile
                    v-for="(searchItem, index) in searchTileData"
                    :key="index"
                    :prop-search-item="searchItem"
                    @remove-filter-tile="handleRemoveFilterTile"
                  />
                </div>
                <div class="community-members-search-container">
                  <div class="community-members-search">
                    <el-input
                      v-model="searchText"
                      placeholder="Search community"
                    >
                      <template #prefix>
                        <el-icon class="el-input__icon">
                          <search />
                        </el-icon>
                      </template>
                      <template #suffix>
                        <img
                          v-if="searchText"
                          src="../assets/community_index/close-button.png"
                          alt="close_button"
                          style="height: 20px;width: 20px;"
                          @click="searchText = ''"
                        >
                      </template>
                    </el-input>
                  </div>
                  <div
                    v-if="false"
                    class="community-members-search-icon-container"
                  >
                    <div>
                      <img
                        src="../assets/community_index/sort-icon.png"
                        alt="FariaOne"
                        style="height: 18px; width: 18px"
                      >
                    </div>
                    <div>
                      <img
                        src="../assets/community_index/grid-view-icon.png"
                        alt="FariaOne"
                        style="height: 18px; width: 18px"
                      >
                    </div>
                  </div>
                </div>
                <div class="search-row-separator" />
                <div class="community-members-cards-container">
                  <CommunityIndexMemberCard
                    v-for="(member, index) in filteredMembers"
                    :key="index"
                    :prop-certification-path="propCertificationPath"
                    :prop-member="member"
                    :prop-is-lazy-loaded="handleIsLazyLoaded(index)"
                  />
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <!-- mobile -->
    <div
      :style="commonMarginClass"
      class="mobile-community-content"
    >
      <el-row
        id="sticky-filter-tile"
        class="sticky-filter-tile"
      >
        <el-col>
          <div class="community-members-container">
            <div class="community-members-filter-container">
              <div
                class="community-members-filter-icons-container"
                @click="searchModalDialogVisible = true"
              >
                <div class="community-members-filter-asc-sort">
                  <img
                    src="../assets/community/filter-logo.png"
                    alt="FariaOne"
                    style="height: 18px; width: 18px"
                  >
                </div>
                <div class="community-members-filter-button">
                  Filter
                  <img
                    src="../assets/community_index/chevron_right.svg"
                    alt="chevron_right"
                  >
                </div>
              </div>
              <div
                v-if="false"
                class="community-members-sort-icons-container"
              >
                <div class="community-members-filter-asc-sort">
                  <img
                    src="../assets/community_index/asc-sort-icon.png"
                    alt="FariaOne"
                    style="height: 18px; width: 18px"
                  >
                </div>
                <div class="community-members-title-alpha-sort">
                  <img
                    src="../assets/community_index/alpha-sort-icon.png"
                    alt="FariaOne"
                    style="height: 18px; width: 18px"
                  >
                </div>
              </div>
            </div>
            <div class="community-members-title-container hidden-lg-and-up">
              <div class="community-members-title-text">
                <span
                  v-if="searchResultText.text !== noMatchHtml"
                  class="members-count"
                >{{ searchResultText.count }}</span><span v-html="searchResultText.text" />
              </div>
            </div>
            <div
              v-if="isSearchTileDataAvailable"
              class="community-members-selected-search-items hidden-lg-and-up"
            >
              <CommunityIndexSearchTile
                v-for="(searchItem, index) in searchTileData"
                :key="index"
                :prop-search-item="searchItem"
                @remove-filter-tile="handleRemoveFilterTile"
              />
            </div>
            <div class="community-members-search-container hidden-lg-and-up">
              <div class="community-members-search">
                <el-input
                  v-model="searchText"
                  placeholder="Search community"
                >
                  <template #prefix>
                    <el-icon class="el-input__icon">
                      <search />
                    </el-icon>
                  </template>
                  <template #suffix>
                    <img
                      v-if="searchText"
                      src="../assets/community_index/close-button.png"
                      alt="close_button"
                      style="height: 20px;width: 20px;"
                      @click="searchText = ''"
                    >
                  </template>
                </el-input>
              </div>
              <div
                v-if="false"
                class="community-members-search-icon-container"
              >
                <div>
                  <img
                    src="../assets/community_index/sort-icon.png"
                    alt="FariaOne"
                    style="height: 18px; width: 18px"
                  >
                </div>
                <div>
                  <img
                    src="../assets/community_index/grid-view-icon.png"
                    alt="FariaOne"
                    style="height: 18px; width: 18px"
                  >
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="community-members-cards-container hidden-lg-and-up">
        <CommunityIndexMemberCard
          v-for="(member, index) in filteredMembers"
          :key="index"
          :prop-certification-path="propCertificationPath"
          :prop-member="member"
          :prop-is-lazy-loaded="handleIsLazyLoaded(index)"
        />
      </div>
    </div>
    <CommunitySearchMenuMobileDialog
      :prop-dialog-visible="searchModalDialogVisible"
      :prop-badge-categories="badgeCategories"
      :prop-category-regions="categoryRegions"
      :prop-community-path="propCommunityPath"
      :prop-certification-path="propCertificationPath"
      :prop-selected-regions="selectedRegions"
      :prop-search-result-text="searchResultText"
      @close-dialog="handleCloseDialog"
      @search-params-changed="handleParamsChanged"
      @badges-search-text-changed="handleBadgesSearch"
    />
  </div>
</template>
<script>
import "element-plus/theme-chalk/index.css";
import "element-plus/theme-chalk/display.css";
import screenSizeMixin from "../mixins/screenSizeMixin.js";
import CommunityIndexSearchTile from "./CommunityIndexSearchTile.vue";
import CommunityBadgesAndRegionSearchMenu from "./CommunityBadgesAndRegionSearchMenu.vue";
import CommunitySearchMenuMobileDialog from "./CommunitySearchMenuMobileDialog.vue";
import CommunityIndexMemberCard from "./CommunityIndexMemberCard.vue";
import { cloneDeep } from "lodash";

export default {
  components: {
    CommunityIndexSearchTile,
    CommunityIndexMemberCard,
    CommunityBadgesAndRegionSearchMenu,
    CommunitySearchMenuMobileDialog,
  },
  mixins: [screenSizeMixin],
  props: {
    propBadgeCategories: {
      type: Array,
      default: () => [],
    },
    propBreadCrumMargin: {
      type: Number,
      default: () => 0,
    },
    propCertificationPath: {
      type: String,
      default: () => "",
    },
    propCommunityPath: {
      type: String,
      default: () => "",
    },
    propMargin: {
      type: Number,
      default: () => 0,
    },
    propMembers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      avatarDisplay: false,
      badgeCategories: this.propBadgeCategories,
      searchModalDialogVisible: false,
      descriptionExpanded: false,
      communityTabs: [
        {
          value: "community",
          label: "Community",
          url: "#",
        },
        {
          value: "certifications",
          label: "Certification",
          url: "https://my.faria.org/certification",
        },
      ],
      selectedCommunityMobileTab: 0,
      searchText: "",
      categoryRegions: ["Americas", "Asia-Pacific", "EMEA"],
      selectedRegions: [],
      searchTileData: [],
      skip: 4,
      dummyKey: 1,
      orignalTopPosition: 0,
      searchTileTopMargin: 150, // ~150px
      noMatchHtml:
        "Oops! We couldn't find any results matching your search. But don't worry, our line-up is always expanding, so watch this space. While you're here, why not recommend talented individuals? Reach out to our team at <a href='mailto: community@faria.org'>community@faria.org</a> if we just described you! We're always looking to add new voices to our community.",
    };
  },
  computed: {
    commonMarginClass() {
      return {
        marginLeft: `${this.propMargin}px`,
        marginRight: `${this.propMargin}px`,
      };
    },
    isSearchTileDataAvailable() {
      return this.searchTileData.length > 0;
    },
    searchTileRegionsData() {
      return this.searchTileData
        .filter((data) => data.type === "region")
        .map((data) => data.region);
    },
    searchTileBadgesData() {
      return this.searchTileData
        .filter((data) => data.type === "badge")
        .map((badge) => `${badge.category}${badge.name}`);
    },
    filteredMembers() {
      if (this.propMembers.length === 0) {
        return [];
      }

      let fullMemberList = cloneDeep(this.propMembers);

      if (this.searchTileRegionsData.length > 0) {
        fullMemberList = fullMemberList.filter((member) =>
          this.searchTileRegionsData.includes(member.region)
        );
      }

      if (this.searchTileBadgesData.length > 0) {
        fullMemberList = fullMemberList.filter((member) =>
          member.badges.some((badge) =>
            this.searchTileBadgesData.includes(`${badge.category}${badge.name}`)
          )
        );
      }

      if (this.searchText) {
        fullMemberList = fullMemberList.filter((member) => {
          let fullName = `${member.first_name} ${member.last_name}`;
          const searchRegex = new RegExp(`${this.searchText.trim()}`, "i");
          const searchResult = fullName.match(searchRegex);
          return !!searchResult;
        });
      }

      return fullMemberList;
    },
    numberOfMembers() {
      return this.filteredMembers.length;
    },
    onlyOneMember() {
      return this.numberOfMembers === 1;
    },
    searchResultText() {
      let searchResultObj = {};
      searchResultObj.count = this.numberOfMembers;
      if (this.onlyOneMember) {
        searchResultObj.text = "Faria community member"
        searchResultObj.filterModalBtnText = "Show 1 Member"
      }else {
        searchResultObj.text = "Faria community members"
        searchResultObj.filterModalBtnText = `Show ${this.numberOfMembers} Members`
      }

      if (
        (this.searchText ||
          this.searchTileBadgesData.length > 0 ||
          this.searchTileRegionsData.length > 0) &&
        this.numberOfMembers === 0
      ) {
        searchResultObj.text = this.noMatchHtml;
        searchResultObj.count = null;
        searchResultObj.filterModalBtnText = "No matches found"
      }

      return searchResultObj;
    },
    currentUrl() {
      return window.location.origin;
    },
  },
  created() {
    window.addEventListener("pageshow", (event) => {
      if (event.persisted) {
        this.dummyKey = this.dummyKey + 1;
        this.selectedCommunityMobileTab = 0;
      }
    });
  },
  mounted() {
    const stickyTile = document.getElementById("sticky-filter-tile")
    if (stickyTile) this.orignalTopPosition  = stickyTile.getBoundingClientRect().top
  },
  unmounted() {
    window.removeEventListener("pageshow", () => {});
  },
  methods: {
    handleRemoveFilterTile(filterTile) {
      const { type } = filterTile;

      if (type === "badge") {
        const { group, name } = filterTile;

        this.badgeCategories.forEach((category) => {
          if (category.name === group) {
            category.selectedBadges = category.selectedBadges.filter(
              (badge) => badge !== name
            );
          }
        });
      }

      if (type === "region") {
        let selectedRegions = [...this.selectedRegions].filter(
          (region) => region !== filterTile.region
        );
        this.selectedRegions = selectedRegions;
      }
    },
    handleSearchTileData() {
      let searchTileData = [];

      this.badgeCategories.forEach((badgeCategory) => {
        if (badgeCategory.selectedBadges.length > 0) {
          const formatSelectedBadges = badgeCategory.badges
            .filter((badge) =>
              badgeCategory.selectedBadges.includes(badge.name)
            )
            .map((badge) => {
              return { ...badge, group: badgeCategory.name, type: "badge" };
            });
          searchTileData = searchTileData.concat(formatSelectedBadges);
        }
      });

      this.selectedRegions.forEach((region) => {
        searchTileData.unshift({ type: "region", region });
      });

      this.searchTileData = searchTileData;
    },
    handleParamsChanged(searchParamsData) {
      const { selectedRegions, badgeCategories } = searchParamsData;
      this.selectedRegions = selectedRegions;
      this.badgeCategories = badgeCategories;
      this.handleSearchTileData();
    },
    handleBadgesSearch(badgeSearchtext) {
      if (!badgeSearchtext) {
        this.badgeCategories = this.propBadgeCategories;
        return;
      }

      let badgeCategories = cloneDeep(this.propBadgeCategories);

      badgeCategories = badgeCategories
        .map((category) => {
          const searchRegex = new RegExp(`${badgeSearchtext.trim()}`, "i");
          category.badges = category.badges.filter((badge) => {
            const searchResult = badge.name.match(searchRegex);
            return !!searchResult;
          });

          return category;
        })
        .filter((category) => category.badges.length > 0);

      this.badgeCategories = badgeCategories;
    },
    handleMobileCommunityTabClick(selectedCommunityMobileTab) {
      const url = this.communityTabs[selectedCommunityMobileTab].url;
      this.selectedCommunityMobileTab = 0;
      this.openUrl(url);
    },
    openUrl(url, args = "_self") {
      window.open(url, args);
    },
    handleIsLazyLoaded(index) {
      return index > this.skip - 1;
    },
    handleCloseDialog() {
      document.getElementById("main").scrollTop = this.orignalTopPosition - this.searchTileTopMargin;
      this.searchModalDialogVisible = false;
    },
  },
};
</script>
<style src="element-plus/theme-chalk/index.css"></style>
<style lang="scss" scoped>
@import "../css/default-settings";

.top-banner {
  background-image: linear-gradient(
    360deg,
    rgba(48, 94, 176, 0.65) 0%,
    rgba(217, 231, 247, 0) 88.37%
  );
  position: relative;
  background-color: #fff;
  background-position: 50%;
  background-size: 100%;
  background-size: cover;
}

.top-banner:after {
  content: "";
  background-image: url("../assets/community_index/community-pattern.png");
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  opacity: 0.8;
  z-index: 0;
}

.commuinity-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.sticky-filter-tile {
  position: sticky;
  position: -webkit-sticky;
  z-index: 15;
  top: 0px;
}

.community-tabs {
  margin-top: 32px;
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  height: 56px;
  border: 1px solid #305eb0;
  @include for-desktop-content {
    z-index: 5;
  }

  a:first-child {
    color: #ffffff;
    background: #305eb0;
  }

  a:nth-child(2) {
    color: #305eb0;
    background: #ffffff;
  }

  a:hover {
    color: #ffffff;
    background: #305eb0;
  }

  .community-tab-item {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    padding: 20px 32px;
    color: #305eb0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 165px;
    height: 56px;
  }

  @include for-tablet-and-mobile-content {
    display: none;
  }
}

.community-logo {
  margin-top: 20px;

  @include for-desktop-content {
    z-index: 5;
  }

  img {
    width: 80px;
  }
}

.community-title {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #305eb0;

  @include for-desktop-content {
    z-index: 5;
  }
}

.community-sub-title {
  margin-top: 20px;
  font-weight: 400;
  font-size: 21px;
  line-height: 36px;
  text-align: center;
  color: #333333;
  margin-bottom: 40px;

  @include for-desktop-content {
    z-index: 5;
  }
}

.community-mission-statement {
  margin-bottom: 192px;
  z-index: 5;
}

.mission-btn {
  display: flex;
  justify-content: center;

  a {
    padding: 18px 20px 18px 24px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #d8d8d8;
    background: #fff;
    color: #478cfe !important;
    text-align: center;
    font-size: 16px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: capitalize;
    transition: transform 300ms ease 0ms;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;

    img {
      width: 20px;
      height: 20px;
    }
  }

  a:hover {
    transform: translateX(0px) translateY(-2px) !important;
    color: #478cfe !important;
  }
}

.desktop-community-content {
  margin-top: -128px;
  max-width: 1080px;
  margin-bottom: 40px;

  @include for-tablet-and-mobile-content {
    display: none;
  }
}

.mobile-community-content {
  margin-top: -128px;
  max-width: 1080px;

  @include for-desktop-content {
    display: none;
  }
}

.community-members-container {
  border-radius: 24px;
  background: white;
  height: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 1px rgba(51, 109, 205, 0.3),
    0px 9px 14px -4px rgba(51, 109, 205, 0.1);

  &.hidden-lg-and-up {
    box-shadow: none;
    border-radius: 0px 0px 24px 24px;
  }
}

.community-members-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 36px 24px 40px;

  &.hidden-lg-and-up {
    margin: 20px;
  }
}

.community-members-title-text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: #333333;

  & > .members-count {
    font-weight: 700;
    margin-right: 8px;
  }
}

.community-members-title-icons-container {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}

.community-members-selected-search-items {
  @include hide-scroll-bar;
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  overflow: scroll;
  margin-bottom: 24px;
  min-height: 56px;
  margin-right: 8px;

  > div:first-child {
    margin-left: 40px;
  }

  &.hidden-lg-and-up {
    > div:first-child {
      margin-left: 20px;
    }
  }
}

.community-members-selected-search-items::-webkit-scrollbar {
  display: none;
}

.community-members-selected-search-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 20px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 40px;
  height: 56px;
  flex: 0 0 auto;
}

.view-all-tile {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 20px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 40px;
  height: 56px;
  flex: 0 0 auto;

  div:first-child {
    margin-left: 0px;
  }
}

.view-all-text {
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  color: #478cfe;
  cursor: pointer;
}

.view-all-text:after {
  content: url("../assets/community_index/icon-arrow-right.png");
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.community-members-search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 36px 24px 40px;

  @include for-tablet-and-mobile-content {
    margin: 0px 36px 20px 20px;
  }
}

.community-members-search-icon-container {
  display: flex;
  column-gap: 16px;
}

.community-members-search {
  width: 60%;

  ::v-deep(.el-input__wrapper) {
    border-radius: 24px;
  }

  ::v-deep(.el-input__prefix) {
    left: 12px;
    top: 10px !important;
    cursor: pointer;
  }

  ::v-deep(.el-input__suffix) {
    right: 10px;
    top: 10px !important;
    cursor: pointer;
  }

  ::v-deep(.el-input__inner) {
    height: 40px;
  }

  @media screen and (max-width: 425px) {
    width: 85%;
  }
}

.community-members-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 20px 0 20px;
}

.community-members-filter-text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: #333333;
  cursor: pointer;

  & > .members-count {
    font-weight: 700;
    margin-right: 8px;
  }
}

.community-members-filter-icons-container {
  display: flex;
  flex-direction: row;
  column-gap: 13px;
  align-items: center;
  cursor: pointer;
}

.community-members-sort-icons-container {
  display: flex;
  flex-direction: row;
  column-gap: 16.88px;
  align-items: center;
}

.community-members-filter-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}

.community-members-filter-button {
  padding: 17px 24px;
  min-height: 60px;
  display: flex;
  align-items: center;
  font-weight: 700;
  background: #fff;
  color: #3E81FE;
  border: 1px solid #d8d8d8;
  text-transform: none;
  border-radius: 10px;
  font-size: 16px;
  text-align: left;

  img {
      width: 20px;
      height: 20px;
    }
}

.community-members-filter-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 20px 30px -6px #336dcd1a,0 0 1px 0 #336dcd33;
}

.search-row-separator {
  border-bottom: 1px solid #9f9f9f;
  margin-top: 20px;
  margin-bottom: 30px;
  margin: 0px 40px 0px 40px;
}

.community-members-cards-container {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin: 40px;
  justify-content: space-between;

  @include for-tablet-and-mobile-content {
    margin: 40px 0px 24px 0px;
    justify-content: center;
  }
}

.community-tabs-mobile {
  margin-top: 32px;
  width: 100%;
  z-index: 3;

  ::v-deep(.el-select .el-input .el-select__caret.el-icon) {
    color: white;
  }

  ::v-deep(.el-input--suffix .el-input__inner) {
    text-align: center;
  }

  ::v-deep(.el-popper__arrow) {
    display: none;
  }

  ::v-deep(.el-input__inner) {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    color: #ffffff;
  }

  ::v-deep(.el-input__wrapper) {
    height: 56px;
    background: #305eb0;
    box-shadow: 0px 7px 14px rgba(50, 50, 93, 0.1),
      0px 3px 6px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }

  ::v-deep(.el-select) {
    width: 100%;
  }

  ::v-deep(.el-select-dropdown__item) {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    align-items: center;
    height: 56px;
    display: inline-grid;
  }

  ::v-deep(.el-select-dropdown__item).selected {
    color: #d8d8d8;
  }

  ::v-deep(.el-select-dropdown__item):hover {
    background: #305eb0;
    color: #ffffff;
  }

  ::v-deep(.el-select-dropdown__item.selected):hover {
    color: #d8d8d8;
    background: #ffffff;
  }

  ::v-deep(.el-select-dropdown__list) {
    display: flex;
    padding: 0;
    flex-direction: column;
    justify-items: center;
  }

  ::v-deep(.el-select-dropdown__wrap) {
    max-height: 100%;
  }

  ::v-deep(.el-scrollbar) {
    > .el-scrollbar__bar {
      opacity: 1;
    }
  }

  @include for-desktop-content {
    display: none;
  }
}
</style>
