<template>
  <div class="footer-link">
    <div class="footer-link-inner">
      <p>
        <strong>
          <span v-if="propLinksGroup.title.isText">{{
            propLinksGroup.title.name
          }}</span>
          <a
            v-else
            :href="propLinksGroup.title.url"
          >{{
            propLinksGroup.title.name
          }}</a>
        </strong>
      </p>
      <ul>
        <li
          v-for="(link, index) in propLinksGroup.links"
          :key="index"
        >
          <a :href="link.url">{{ link.name }}</a>
          <span
            v-if="link.name === 'Schools Directory'"
            id="label-new"
          >New</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import "element-plus/theme-chalk/display.css";
export default {
  props: {
    propLinksGroup: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style src="element-plus/theme-chalk/display.css"></style>
<style lang="scss" scoped>
#label-new {
  background-color: #ee5a54;
  color: #fff;
  border-radius: 4px;
  padding: 3px 10px;
  font-size: 12px;
  margin-left: 4px;
}
.footer-link {
  margin-bottom: 24px;

  p {
    padding-bottom: 5px;
  }

  ul {
    list-style: none;
    margin-top: 0px;
    padding-left: 0;
    padding-bottom: 0 !important;
  }

  ul li {
    line-height: 1.5em;
    margin-bottom: 6px;
  }

  strong a {
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    color: #333 !important;
  }

  strong {
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    color: #333 !important;
  }

  li a {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #666666 !important;
  }

  li a:hover {
    color: #478CFE !important;
}
}
</style>
