<template>
  <div class="common-layout">
    <CommunityHeader />
    <el-main id="main">
      <CommunityBreadCrumbs
        :prop-bread-crumbs="[
          {
            name: 'All Services',
            icon: getImgUrl('community/breadcrumbs-faria.png'),
            href: 'https://www.faria.org/'
          },
          {
            name: 'Community',
            icon: getImgUrl('community_index/community-hands.png'),
          }
        ]"
      />
      <CommunityIndexMainContent
        :prop-margin="margin"
        :prop-badge-categories="badgeCategories"
        :prop-bread-crum-margin="breadCrumMargin"
        :prop-members="members"
        :prop-certification-path="propCertificationPath"
        :prop-community-path="propCommunityPath"
      />
      <CommunityFooter
        :prop-footer-margin="footerMargin"
        :prop-width="width"
        :prop-max-width="maxWidth"
      />
    </el-main>
  </div>
</template>

<script>
import "element-plus/theme-chalk/index.css";
import "element-plus/theme-chalk/display.css";
import screenSizeMixin from "../mixins/screenSizeMixin.js";
import CommunityMixin from "../mixins/CommunityMixin.js";
import CommunityHeader from "../components/CommunityHeader.vue";
import CommunityIndexMainContent from "../components/CommunityIndexMainContent.vue";
import CommunityBreadCrumbs from "../components/CommunityBreadCrumbs.vue";
import CommunityFooter from "../components/CommunityFooter.vue";
import { cloneDeep } from "lodash";

export default {
  components: {
    CommunityFooter,
    CommunityIndexMainContent,
    CommunityHeader,
    CommunityBreadCrumbs
  },
  mixins: [screenSizeMixin, CommunityMixin],
  props: {
    propMembers: {
      type: Array,
      default: () => []
    },
    propBadgeCategories: {
      type: Array,
      default: () => []
    },
    propCertificationPath: {
      type: String,
      default: ""
    },
    propCommunityPath: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      badgeCategories: [],
      members: []
    };
  },
  computed: {
    currentUrl() {
      return window.location.origin;
    },
    commonMarginClass() {
      return {
        marginLeft: `${this.headerMargin}px`,
        marginRight: `${this.headerMargin}px`,
        width: this.width,
        maxWidth: this.maxWidth
      };
    }
  },
  beforeMount() {
    this.initializeBadgeCategories();
    this.handleInitialMemberBadges();
  },
  methods: {
    initializeBadgeCategories() {
      let badgeCategories = cloneDeep(this.propBadgeCategories);
      badgeCategories = badgeCategories.map(category => {
        category.selectedBadges = [];
        if (category.name === "ManageBac") {
          category.badges = [...category.badges].filter(
            badge =>
              !["Training Academy", "Certified Educator"].includes(badge.name)
          );
        }

        // sort each member badge by category and then badge name
        badgeCategories.forEach(category => {
          category.badges = this.sortMemberBadges(category.badges);
        });

        return category;
      });

      this.badgeCategories = badgeCategories;
    },
    handleInitialMemberBadges() {
      let members = cloneDeep(this.propMembers);

      // sort each member badge by category and then badge name
      members.forEach(member => {
        member.badges = this.sortMemberBadges(member.badges);
      });

      this.members = members;
    },
    getImgUrl(imagePath) {
      return require(`../assets/${imagePath}`);
    }
  }
};
</script>

<style src="element-plus/theme-chalk/index.css"></style>
<style lang="scss" scoped>
@import "../css/default-settings";

.common-layout {
  font-family: "Open Sans", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 76px;

    @include for-tablet-and-mobile-content {
  height: 100vh;
  }
}

::v-deep(.el-container) {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

::v-deep(.el-header) {
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  padding: 0;
  height: 76px !important;

  @include for-tablet-and-mobile-content {
    position: absolute;
  }
}

::v-deep(.el-main) {
  padding: 0;
  background: #f6f9fc !important;

  @include for-desktop-content {
    margin-top: 60px;
  }
}

.el-menu-wrapper {
  display: flex;
  height: 100%;
  border: 0;
  align-items: center;
  width: auto;

  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #333333 !important;
    margin-left: 0px !important;
  }
}
</style>
