<template>
  <el-dialog
    v-model="dialogVisible"
    width="100%"
    :fullscreen="true"
    :before-close="handleClose"
  >
    <template #header="{ close, titleId, titleClass }">
      <div
        class="header-close-icon"
        @click="$emit('close-dialog')"
      >
        <img
          src="../assets/community_index/close-button.png"
          alt="FariaOne"
          style="height: 20px;width: 20px;"
        >
      </div>
    </template>
    <CommunityBadgesAndRegionSearchMenu
      :prop-badge-categories="propBadgeCategories"
      :prop-category-regions="propCategoryRegions"
      :prop-community-path="propCommunityPath"
      :prop-search-result-text="propSearchResultText"
      :prop-certification-path="propCertificationPath"
      :prop-selected-regions="propSelectedRegions"
      :prop-on-mobile="true"
      @close-dialog="$emit('close-dialog')"
      @search-params-changed="handleParamsChanged"
      @badges-search-text-changed="handleBadgesSearch"
    />
  </el-dialog>
</template>

<script>
import CommunityBadgesAndRegionSearchMenu from "./CommunityBadgesAndRegionSearchMenu.vue";

export default {
  components: {
    CommunityBadgesAndRegionSearchMenu
  },
  props: {
    propDialogVisible: {
      type: Boolean,
      default: () => false
    },
    propBadgeCategories: {
      type: Array,
      default: () => []
    },
    propCategoryRegions: {
      type: Array,
      default: () => []
    },
    propCertificationPath: {
      type: String,
      default: () => ""
    },
    propCommunityPath: {
      type: String,
      default: () => ""
    },
    propSelectedRegions: {
      type: Array,
      default: () => []
    },
    propSearchResultText: {
      type: Object,
      default: () => {}
    }
  },
  emits: ["close-dialog", "search-params-changed", "badges-search-text-changed"],
  data() {
    return {
      dialogVisible: this.propDialogVisible
    };
  },
  watch: {
    propDialogVisible: {
      handler(newValue) {
        this.dialogVisible = newValue;
      }
    }
  },
  methods: {
    handleClose(done) {
      this.$emit("close-dialog");
      done();
    },
    handleParamsChanged(searchParamsData) {
      this.$emit("search-params-changed", searchParamsData);
    },
    handleBadgesSearch(badgeSearchtext) {
      this.$emit("badges-search-text-changed", badgeSearchtext);
    }
  }
};
</script>

<style lang="scss">

.el-dialog__headerbtn {
  display: none;
}

.dialog-header {
  display: flex;
  margin: 10px 0px 16px 0px;
  justify-content: space-between;
  align-items: center;
}

.header-close-icon {
  display: flex;
  cursor: pointer;
}

.search-menu-dialog-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: -0.05em;
  color: #305eb0;
  margin-bottom: 32px;
}
</style>
