<template>
  <a
    v-observe-visibility="visibilityChanged"
    class="community-member-card"
    :href="formattedProfileUrl"
    target="_self"
  >
    <div class="community-member-avatar-container">
      <div :style="blurStyle" />
      <div class="community-member-avatar-inner">
        <img
          v-if="!isLazyLoaded"
          class="community-member-avatar"
          :src="propMember.avatar"
        >
      </div>
    </div>
    <div class="community-member-badges">
      <div
        v-for="(badge, index) in formattedBadges.badges"
        :key="index"
        class="community-member-badge"
      >
        <a :href="createBadgeLink(propCertificationPath, badge)">
          <el-tooltip
            placement="bottom"
            popper-class="community-badge-popper"
            :content="badge.short_description"
          >
            <img
              :src="badge.image"
              class="badge-icon"
              alt="FariaOne"
            >
          </el-tooltip>
        </a>
      </div>
      <div
        v-if="formattedBadges.more"
        class="community-member-badge-more"
      >
        <div class="community-member-badge-more-count">
          +{{ formattedBadges.more }}
        </div>
      </div>
    </div>
    <div class="community-member-name">
      {{ `${propMember.first_name} ${propMember.last_name}` }}
    </div>
    <div class="community-member-title">
      {{ propMember.title }}
    </div>
    <div class="community-member-school">
      {{ propMember.organization_name }}
    </div>
  </a>
</template>
<script>
import "element-plus/theme-chalk/index.css";
import "element-plus/theme-chalk/display.css";
import CommunityMixin from "../mixins/CommunityMixin.js";

export default {
  mixins: [CommunityMixin],
  props: {
    propMember: {
      type: Object,
      default: () => {}
    },
    propIsLazyLoaded: {
      type: Boolean,
      default: false
    },
    propCertificationPath: {
      type: String,
      default: ""
    },
    propIntersectionObserverOption: {
      type: Object,
      default: () => ({ root: null })
    }
  },
  data() {
    return {
      isLazyLoaded: null
    };
  },
  computed: {
    formattedBadges() {
      const badges = this.sortMemberBadges(this.propMember.badges);
      const numberOfBadges = badges.length;

      return numberOfBadges > 3
        ? { badges: badges.slice(0, 3), more: numberOfBadges - 3 }
        : { badges, more: null };
    },
    formattedProfileUrl() {
      return `${window.location.protocol}//${this.propMember.profile_url}`;
    },
    blurStyle() {
      let style = {
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "206px",
        width: "300px",
        position: "absolute",
        zIndex: "0",
        filter: "blur(48px)"
      };

      if (!this.isLazyLoaded) {
        style.backgroundImage = `url(${this.propMember.avatar})`;
      }

      return style;
    }
  },
  created() {
    this.isLazyLoaded = this.propIsLazyLoaded;
  },
  methods: {
    reduceBadge(badge) {
      return "ManageBac-Subject Leader" === `${badge.category}-${badge.name}`;
    },
    visibilityChanged(isVisible) {
      if (isVisible && this.isLazyLoaded) {
        this.isLazyLoaded = false;
      }
    }
  }
};
</script>

<style src="element-plus/theme-chalk/index.css"></style>
<style lang="scss" scoped>
.community-member-card {
  padding: 0px;
  width: 300px;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  overflow: hidden;
  cursor: pointer;
  transform: translateZ(0);
  box-shadow: 0 5px 8px -2px rgba(0, 66, 112, 0.1), 0 0 1px 0 rgba(0, 66, 112, 0.2);
}

.community-member-card:hover {
  translate: 0 -2px;
  box-shadow: 0 20px 30px -6px rgba(0, 66, 112 ,0.1), 0 0 1px 0 rgba(0, 66, 112, 0.2);
}
.community-member-avatar-container {
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px 11px 0px 0px;
}

.community-member-avatar-inner {
  padding: 24px;
  height: 206px;
  width: auto;
}

.community-member-avatar {
  border: 8px solid white;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  width: 164px;
  height: 164px;
  position: relative;
  z-index: 5;
}

.community-member-badges {
  display: flex;
  column-gap: 8px;
  padding: 0px 24px;
  height: 72px;
  background: #f8fbfe;
  align-items: center;

  .badge-icon {
    height: 64px;
    width: 64px;
    filter: drop-shadow(0px 2px 4px #0042701A);
  }
}

.community-member-badge-more {
  padding: 0px 8px;
  width: 100%;
  height: 24px;
  background: #9f9f9f;
  border-radius: 16px;
}

.community-member-badge-more-count {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: #ffffff;
}

.community-member-name {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #333333;
  margin: 28px 24px 4px 24px;
  word-break: break-word;
}

.community-member-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  margin: 0px 24px 12px 24px;
  word-break: break-word;
}

.community-member-school {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  margin: 0px 24px 24px 24px;
  word-break: break-word;
}

</style>